import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from 'src/components/layout';
import Features from 'src/components/features';

import { HERO } from 'src/data/features';

export default function FeaturesPage({ data }) {
  return (
    <Layout title="Features" description={HERO.title}>
      <Helmet>
        <link
          rel="preload"
          href="/FiraCode-Regular.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous" // https://developer.mozilla.org/en-US/docs/Web/HTML/Preloading_content#cors-enabled_fetches
        />
      </Helmet>

      <Features images={data.featuresImages.edges} videos={data.featuresVideos.edges} />
    </Layout>
  );
}

export const query = graphql`
  query FeaturesQuery {
    featuresImages: allFile(filter: { sourceInstanceName: { eq: "image-features" } }) {
      edges {
        node {
          id
          name

          childImageSharp {
            gatsbyImageData(quality: 80, width: 640)
          }
        }
      }
    }

    featuresVideos: allFile(filter: { sourceInstanceName: { eq: "video-features" } }) {
      edges {
        node {
          id
          name

          mp4: videoH264(maxWidth: 640) {
            path
          }

          mp4Retina: videoH264(maxWidth: 1280) {
            path
          }

          vp9: videoVP9(maxWidth: 640) {
            path
          }

          vp9Retina: videoVP9(maxWidth: 1280) {
            path
          }

          posters: videoScreenshots(timestamps: ["1"], width: 640) {
            publicURL
          }
        }
      }
    }
  }
`;
