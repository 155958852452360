import React from 'react';
import clsx from 'clsx';

export default function FeatureGridItem({ icon, heading, subheading }) {
  return (
    <li className="flex flex-col w-70 justify-center items-center text-center space-y-4 sm:space-y-6 sm:w-full">
      <div
        className={clsx(
          'flex items-center justify-center flex-shrink-0',
          'w-10 h-10 text-yellow-500 bg-yellow-50 rounded-full select-none'
        )}
      >
        {icon}
      </div>
      <div className="space-y-2 sm:space-y-3">
        <h3 className="font-semibold text-lg leading-tight md:text-xl">{heading}</h3>
        <div className="font-normal text-sm leading-normal md:text-base">{subheading}</div>
      </div>
    </li>
  );
}
