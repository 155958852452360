import React from 'react';
import clsx from 'clsx';

import { HERO, FEATURES_LIST, FEATURES_GRID } from 'src/data/features';
import FeatureWithImage from './feature-with-image';
import PrivacyTile from './privacy-tile';
import FeatureGridItem from './feature-grid-item';

export default function Features({ images, videos }) {
  return (
    <div className="relative flex flex-col items-center">
      <div className="w-full pb-8 px-6 sm:px-10 max-w-screen-xl">
        <main className="max-w-full flex flex-col items-center mt-8 space-y-12 md:space-y-32">
          <section className="max-w-full flex flex-col items-center space-y-4">
            <h1 className="text-center text-gray-900 whitespace-pre-line font-display font-bold text-2xl leading-8 md:text-4xl md:leading-tight">
              {HERO.title}
            </h1>

            <p className="text-sm text-center text-gray-800 whitespace-pre-line md:text-base">
              {HERO.body}
            </p>
          </section>

          <section className="w-full">
            <ul className="flex flex-col items-center w-full space-y-16 md:space-y-24">
              {FEATURES_LIST({ images, videos }).map((item) => (
                <li className="flex flex-col items-center w-full" key={item.heading}>
                  {renderFeaturesListItem(item)}
                </li>
              ))}
            </ul>
          </section>

          <section className="flex flex-col items-center">
            <h2 className="font-semibold text-2xl leading-tight sm:text-3xl">Plus More!</h2>
            <ul
              className={clsx(
                'grid grid-cols-1 gap-y-12 items-start mt-10',
                'sm:grid-cols-2 sm:gap-y-8 sm:gap-x-8 md:mt-16 md:grid-cols-3 md:gap-x-16 md:gap-y-16'
              )}
              style={{ maxWidth: '968px' }}
            >
              {FEATURES_GRID.map((item) => (
                <FeatureGridItem key={item.heading} {...item} />
              ))}
            </ul>
          </section>
        </main>
      </div>
    </div>
  );
}

function renderFeaturesListItem(item) {
  switch (item.__type) {
    case 'ItemWithImage':
      return <FeatureWithImage {...item} />;
    case 'PrivacyTile':
      return <PrivacyTile {...item} />;
    default:
      return null;
  }
}
