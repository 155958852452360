import React from 'react';
import {
  SearchIcon,
  FlagIcon,
  MenuIcon,
  CollectionIcon,
  MapIcon,
  LinkIcon,
  ExternalLinkIcon,
  ShieldCheckIcon,
  KeyIcon,
  LightningBoltIcon,
  AdjustmentsIcon,
  LightBulbIcon,
  SparklesIcon,
  CheckCircleIcon,
  FilterIcon,
  BellIcon,
  FolderIcon,
  DesktopComputerIcon,
} from '@heroicons/react/solid';

const HERO_ICONS_PROPS = {
  className: 'h-5 w-5',
};

const ICONS = {
  search: <SearchIcon {...HERO_ICONS_PROPS} />,
  flag: <FlagIcon {...HERO_ICONS_PROPS} />,
  menu: <MenuIcon {...HERO_ICONS_PROPS} />,
  collection: <CollectionIcon {...HERO_ICONS_PROPS} />,
  map: <MapIcon {...HERO_ICONS_PROPS} />,
  link: <LinkIcon {...HERO_ICONS_PROPS} />,
  externalLink: <ExternalLinkIcon {...HERO_ICONS_PROPS} />,
  shieldCheck: <ShieldCheckIcon {...HERO_ICONS_PROPS} />,
  key: <KeyIcon {...HERO_ICONS_PROPS} />,
  lightningBolt: <LightningBoltIcon {...HERO_ICONS_PROPS} />,
  adjustments: <AdjustmentsIcon {...HERO_ICONS_PROPS} />,
  lightBulb: <LightBulbIcon {...HERO_ICONS_PROPS} />,
  sparkles: <SparklesIcon {...HERO_ICONS_PROPS} />,
  checkCircle: <CheckCircleIcon {...HERO_ICONS_PROPS} />,
  filter: <FilterIcon {...HERO_ICONS_PROPS} />,
  bell: <BellIcon {...HERO_ICONS_PROPS} />,
  folder: <FolderIcon {...HERO_ICONS_PROPS} />,
  desktopComputer: <DesktopComputerIcon {...HERO_ICONS_PROPS} />,
  apple: <span className="text-2xl">&#63743;</span>,
  command: <span className="text-xl font-bold">&#8984;</span>,
};

export const HERO = {
  title:
    'Tens of features to\u00A0enhance\u00A0your\u00A0workflow.\nAll\u00A0in one\u00A0application.',
  body:
    'Get to know MonoFocus features\u00A0and\u00A0learn\nhow they are going to help you with\u00A0your\u00A0daily\u00A0tasks.',
};

export const FEATURES_LIST = ({ images, videos }) => [
  {
    __type: 'ItemWithImage',
    heading: 'All your tasks accessible from\u00A0the\u00A0menu\u00A0bar',
    image: images.find((v) => v.node.name === 'feature-1').node,
    blocks: [
      {
        icon: ICONS.search,
        heading: 'Search all your tasks',
        description: (
          <p>
            With full-text search support, you will find your tasks even with mispellings or partial
            match.
          </p>
        ),
      },
      {
        icon: ICONS.flag,
        heading: 'See the details',
        description: (
          <p>
            All your deadlines, labels and priorities under your fingertips. Assess your priorities
            without switching between apps.
          </p>
        ),
      },
    ],
  },
  {
    __type: 'ItemWithImage',
    heading: '2-way sync with\u00A0multiple\u00A0apps',
    image: images.find((v) => v.node.name === 'feature-2').node,
    blocks: [
      {
        icon: ICONS.menu,
        heading: 'All your tasks in one place',
        description: (
          <p>
            Always have multiple to-do apps? Browse and complete all your tasks in a single app. You
            can add new tasks too!
          </p>
        ),
      },
      {
        __type: 'ItemWithImage',
        icon: ICONS.collection,
        heading: 'Sync kanban boards',
        description: (
          <div className="space-y-2">
            <div>
              <h5 className="font-semibold">Started working on a new task?</h5>
              <p>MonoFocus will move it to “In Progress” column.</p>
            </div>
            <div>
              <h5 className="font-semibold">Completed a task?</h5>
              <p>Bam, moved to “Done” column automatically.</p>
            </div>
          </div>
        ),
      },
    ],
  },
  {
    __type: 'ItemWithImage',
    heading: 'Link tasks from your\u00A0synced\u00A0projects',
    image: images.find((v) => v.node.name === 'feature-3').node,
    blocks: [
      {
        icon: ICONS.map,
        heading: 'Gather tasks from different projects',
        description:
          'Drag the tasks for today to a single “Today” project, or group them by a common theme, deadline or context. It’s all up to you!',
      },
      {
        icon: ICONS.link,
        heading: 'Complete the task in all projects at once',
        description:
          'Completed tasks will disappear from all projects they were linked to, so you can focus on your work.',
      },
    ],
  },
  {
    __type: 'PrivacyTile',
    heading: 'We treat your privacy seriously',
    blocks: [
      {
        icon: ICONS.shieldCheck,
        heading: 'No personal data leaves your device',
        description:
          'MonoFocus app syncs your projects\ndirectly from your Mac. We can’t store\nor see any of your tasks.',
      },
      {
        icon: ICONS.key,
        heading: 'Your credentials are stored securely',
        description:
          'We utilize the native Apple Keychain to\nsafely store any user credentials used for\nsyncing tasks.',
      },
    ],
  },
  {
    __type: 'ItemWithImage',
    heading: 'Active Task Reminder',
    video: videos.find((v) => v.node.name === 'feature-4').node,
    blocks: [
      {
        icon: ICONS.lightningBolt,
        heading: 'Stay on track',
        description: (
          <p>
            MonoFocus can display a little reminder when you switch between apps. It reminds you
            about your priorities exactly when you need it.
          </p>
        ),
      },
      {
        icon: ICONS.adjustments,
        heading: 'Configurable\nto suit your needs',
        description: (
          <p>
            You can change notification position and frequency, and even have it displayed all the
            time.
          </p>
        ),
      },
    ],
  },
  {
    __type: 'ItemWithImage',
    heading: 'Instant access with\u00A0Quick\u00A0Entry',
    video: videos.find((v) => v.node.name === 'feature-5').node,
    blocks: [
      {
        icon: ICONS.lightBulb,
        heading: 'Add task to any project in a blink',
        description: (
          <p>
            Having a light-bulb moment doesn’t mean interrupting your work. Open Quick Entry with a
            keyboard shortcut, add a task and continue without interruption.
          </p>
        ),
      },
      {
        icon: ICONS.sparkles,
        heading: 'Complete, open, search and\nchange your tasks',
        description: <p>Access most common features just with your keyboard.</p>,
      },
    ],
  },
  {
    __type: 'ItemWithImage',
    heading: 'Customize your workflow for\u00A0synced\u00A0projects',
    video: videos.find((v) => v.node.name === 'feature-6').node,
    blocks: [
      {
        icon: ICONS.checkCircle,
        heading: 'Choose what happens\nafter completing synced task',
        description:
          'Your task can get completed, archived, deleted, or moved to some other list. (available options depend on the synced app).',
      },
      {
        icon: ICONS.filter,
        heading: 'Show only relevant tasks',
        description: 'With labels and assigned filters, you can cut out the unneeded noise.',
      },
    ],
  },
];

export const FEATURES_GRID = [
  {
    icon: ICONS.bell,
    heading: 'Get notified about missing\u00A0active\u00A0task',
    subheading: 'MonoFocus will remind you if you forget to set a next active task.',
  },
  {
    icon: ICONS.folder,
    heading: 'Local Projects',
    subheading:
      'Apart from numerous integrations, you can use local projects to store simple tasks.',
  },
  {
    icon: ICONS.command,
    heading: 'Keyboard Shortcuts',
    subheading:
      'Complete, archive, delete or move your task to another list in its original app. All with a single keystroke.',
  },
  {
    icon: ICONS.apple,
    heading: 'Made for Mac',
    subheading: 'Get the experience and minimal resource usage you’d expect from a native app.',
  },
  {
    icon: ICONS.search,
    heading: 'Powerful Search',
    subheading: 'Easily find the task you’re looking for among all your projects.',
  },
  {
    icon: ICONS.externalLink,
    heading: 'Go to Synced Tasks',
    subheading: 'Open issues, cards and tasks in their original apps with a single click.',
  },
];
