import React from 'react';
import clsx from 'clsx';

export default function PrivacyTile({ heading, blocks }) {
  return (
    <div className="relative w-full px-8 py-10 bg-yellow-1000 text-white shadow-lg rounded-xl overflow-hidden md:pb-20">
      <div
        className="absolute w-full top-2 left-0 font-mono text-brand-400 opacity-5 break-all select-none"
        aria-hidden="true"
      >
        {BACKGROUND_ZEROES_AND_ONES}
      </div>

      <h2 className="font-display font-bold text-2xl leading-tight whitespace-pre-line text-center md:text-3xl">
        {heading}
      </h2>

      <div
        className={clsx(
          'flex flex-col items-center mt-8 space-y-10 text-center',
          'md:flex-row md:items-stretch md:justify-center md:space-y-0 md:space-x-10 md:mt-15 md:text-left lg:space-x-32'
        )}
      >
        {blocks.map(({ icon, heading, description }) => (
          <div
            key={heading}
            className="relative flex flex-col items-center space-y-4 max-w-12xl md:flex-row md:space-x-5 md:space-y-0 md:items-start"
          >
            <div
              className={clsx(
                'flex items-center justify-center flex-shrink-0 z-10 select-none',
                'w-8 h-8 lg:w-10 lg:h-10 text-yellow-500 bg-gray-700 rounded-full shadow-dark-icon-circle'
              )}
            >
              {icon}
            </div>
            <div className="space-y-3">
              <h3 className="opacity-90 font-semibold leading-tight text-base md:text-lg lg:text-xl">
                {heading}
              </h3>
              <div className="opacity-80 font-normal leading-normal text-sm md:text-base lg:whitespace-pre-line">
                {description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const BACKGROUND_ZEROES_AND_ONES = convertStringToBinaryASCII('MonoFocus').repeat(30);

function convertStringToBinaryASCII(string) {
  return [...string]
    .map((character) => character.charCodeAt(0))
    .map((charCode) => charCode.toString(2))
    .map((byte) => byte.padStart(8, '0'))
    .join('');
}
