import { useEffect, useState } from 'react';
import { BrowserService } from '../services/browser-service';

export default function usePixelRatio() {
  const [pixelRatio, setPixelRatio] = useState(BrowserService.devicePixelRatio);

  useEffect(() => {
    const mediaQuery = BrowserService.matchMedia(
      `(resolution: ${BrowserService.devicePixelRatio}dppx)`
    );

    function onPixelRatioChange() {
      setPixelRatio(BrowserService.devicePixelRatio);
    }

    mediaQuery.addListener(onPixelRatioChange);

    return () => mediaQuery.removeListener(onPixelRatioChange);
  }, []);

  return pixelRatio;
}
