import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import usePixelRatio from 'src/hooks/use-pixel-ratio';

export default function FeatureWithImage({ heading, image, video, blocks }) {
  const pixelRatio = usePixelRatio();
  const isPlayingRetina = pixelRatio > 1;

  return (
    <div
      className={clsx(
        'max-w-screen-sm lg:max-w-none flex flex-col items-center justify-between space-y-reverse space-y-4',
        'lg:space-y-0 lg:space-x-8 lg:space-x-12 lg:flex-row'
      )}
    >
      <div className="flex-none lg:w-1/2 xl:w-auto lg:flex-shrink pt-2 space-y-6 sm:space-y-8 order-2 lg:order-1 md:space-y-10 lg:pt-0 lg:pl-16 lg:max-w-14xl">
        <h2 className="font-display font-bold leading-tight text-xl sm:text-2xl md:text-3xl md:whitespace-pre-line">
          {heading}
        </h2>
        <div className="flex flex-col items-start space-y-4 sm:flex-row sm:space-x-8 sm:space-y-0 lg:flex-col lg:space-x-0 lg:space-y-8">
          {blocks.map(({ icon, heading, description }) => (
            <div
              key={heading}
              className="relative w-auto sm:w-1/2 lg:w-auto flex flex-row items-start"
            >
              <div
                className={clsx(
                  'mr-4 transform lg:absolute lg:-left-16 w-8 h-8 lg:w-10 lg:h-10 text-yellow-500 bg-yellow-50 rounded-full select-none',
                  'flex items-center justify-center flex-shrink-0'
                )}
              >
                {icon}
              </div>
              <div className="space-y-2 lg:space-y-3">
                <h3 className="font-semibold leading-tight text-base md:text-lg lg:text-xl">
                  {heading}
                </h3>
                <div className="font-normal leading-normal text-sm md:text-base">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {image ? (
        // HACK: `transform-gpu` fixes otherwise ignored border-radius on Safari.
        <GatsbyImage
          style={{ maxWidth: '640px' }}
          className="lg:w-1/2 xl:w-full flex-shrink xl:flex-none rounded-lg transform-gpu order-1 lg:order-2"
          image={getImage(image)}
          alt={heading}
        />
      ) : null}

      {video ? (
        <figure
          style={{ maxWidth: '640px' }}
          className="lg:w-1/2 xl:w-full flex-shrink xl:flex-none order-1 lg:order-2"
        >
          <video
            loop
            muted
            autoPlay
            className="rounded-lg shadow-md"
            poster={video.posters[0].publicURL}
          >
            <source
              src={isPlayingRetina ? video.vp9Retina.path : video.vp9.path}
              type="video/webm"
            />
            <source
              src={isPlayingRetina ? video.mp4Retina.path : video.mp4.path}
              type="video/mp4"
            />
          </video>
        </figure>
      ) : null}
    </div>
  );
}
